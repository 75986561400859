.admin-page-container {
  min-height: 50vh;
  margin-bottom: 2rem;
  h2 {
    text-align: start;
    margin-top: 3rem;
    margin-bottom: 2rem;
  }
}

.wrap-add-new {
  display: grid;
  gap: 1rem;
}

@media (max-width: 419px) {
  .admin-page-container {
    h2 {
      text-align: center;
    }
  }
  .side-bar {
    width: 80%;
    margin: auto;
    border: 0 !important;
  }
}

@media (min-width: 420px) and (max-width: 799px) {
  .admin-page-container {
    h2 {
      text-align: center;
    }
  }
  .side-bar {
    width: 60%;
    margin: auto;
    border: 0 !important;
  }
}

@media (min-width: 800px) {
  .wrap-add-new {
    grid-template-columns: 2fr 5fr;
  }
}

@media (min-width: 1000px) {
  .wrap-add-new {
    grid-template-columns: 1fr 5fr;
  }
}

@media (min-width: 1250px) {
  .wrap-add-new {
    grid-template-columns: 1fr 6fr;
  }
}

.side-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid hsl(200, 50%, 50%);

  .admin-big-button {
    font-size: 1.2rem;
    width: 80%;
    padding: 0.25em 0.5em;
    border: 0;
    background-color: transparent;
    color: #fff;
    color: #333;
    margin-block: 0.5rem;
    transition: all 0.1s ease;
  }

  .admin-all-btn {
    width: 80%;
    padding: 0.25em 0.5em;
    border: 0;
    background-color: transparent;
    color: #333;
    text-align: start;
    animation-name: changeOpacity;
    animation-duration: 0.7s;

    &:hover {
      color: hsl(200, 50%, 50%);
    }
  }

  .active-admin-btn {
    border-left: 5px solid hsl(200, 50%, 50%) !important;
  }
}

.main-content {
  overflow-y: auto;
}

.product-form-sizes .form-check {
  display: flex;
  .form-check-label {
    margin-left: 1rem !important;
  }
}

.wrapper-admin-table {
  padding-top: 1rem !important;
  h4 {
    font-size: 1.35rem;
    margin-bottom: 2rem;
  }
}

.table > :not(:first-child) {
  border-top: 2px solid hsl(200, 50%, 50%);
}

.edit-product-container {
  padding-top: 2rem;
  form {
    max-width: 800px;
  }
}

.add-new {
  padding-top: 1rem;
  h5 {
    font-size: 1.35rem;
    margin-bottom: 2rem;
  }
}

.edit-product-container .btn-secondary {
  margin-inline-end: 2rem;
}

.edit-product-container .btn-secondary,
.edit-product-container .btn-primary {
  margin-bottom: 2rem;
}

// .add-new {
//   text-align: start;
//   margin-block: 3rem;
// }

// .sizes {
//   label {
//     cursor: pointer;
//   }
//   input {
//     margin-inline-start: 0.3rem;
//     cursor: pointer;
//   }
// }

// .sizes > * {
//   margin-inline: 1rem;
//   font-size: 1.1rem;
// }

// .admin-links {
//   display: grid;
//   gap: 1rem;
// }
// @media (min-width: 440px) {
//   .admin-links {
//     grid-template-columns: repeat(2, 1fr);
//   }
// }
// @media (min-width: 600px) {
//   .admin-links {
//     grid-template-columns: repeat(3, 1fr);
//   }
// }
// @media (min-width: 900px) {
//   .admin-links {
//     grid-template-columns: repeat(4, 1fr);
//   }
// }

// .new-image {
//   width: 300px;
// }

// .admin-big-button {
//   border: 0;
//   display: inline;
//   background-color: #f3f3f3;
//   color: #000;
//   padding: 0.25em 0.5em;
//   cursor: pointer;
//   font-size: 1.6rem;
//   margin-inline: 2rem;
//   margin-block: 1rem;
//   border-radius: 0;
//   transition: background-color 500ms ease;

//   &:hover {
//     background-color: #16437e;
//   }
// }

// .table-all-products {
//   position: relative !important;
//   min-height: 40vh;
// }

// .edit-product-container {
//   text-align: start;
//   form {
//     .mb-3 {
//       width: 80%;
//     }
//   }
// }

// @media (max-width: 768px) {
//   .admin-page-container {
//     margin-top: 4rem;
//     padding-inline: 1.5rem !important;
//   }
//   .edit-product-container {
//     padding-top: 2rem;

//     form {
//       width: 80% !important;
//     }
//   }

//   .table-all-products {
//     // margin-left: -3rem !important;
//   }
// }

// @media (max-width: 550px) {
//   .admin-links {
//     button {
//       width: 100%;
//     }
//   }
//   .edit-product-container {
//     padding-top: 2rem;

//     form {
//       width: 100% !important;
//     }
//   }
// }
