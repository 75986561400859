* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.home-container {
  overflow-x: hidden;
}

body {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  min-height: 100vh;
  margin: 0;
  font-family: "Open Sans", sans-serif;
}

.red-text {
  color: red !important;
}

.shop-btn {
  display: inline-block;
  text-decoration: none;
  background-color: #111 !important;
  color: #fff;
  text-transform: uppercase;
  font-size: 1rem;
  padding: 1em 1.75em;

  svg {
    vertical-align: middle;
    padding-bottom: 3px;
  }

  &:hover,
  &:focus {
    color: #fff !important;
  }
}

.container-padding {
  padding-inline: 20rem;
  position: relative;
}

.margin-bottom-2rem {
  margin-bottom: 2rem !important;
}

#bold-text {
  font-weight: 700;
}

.display-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

//cart animate btn

@media (max-width: 1625px) {
  .container-padding {
    padding-inline: 10rem;
  }
}
@media (max-width: 1301px) {
  .container-padding {
    padding-inline: 5rem;
  }
}

.loader {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  min-height: 100vh;
  background-color: #fff;
}

.clock-loader {
  --clock-color: #333;
  --clock-width: 4rem;
  --clock-radius: calc(var(--clock-width) / 2);
  --clock-minute-length: calc(var(--clock-width) * 0.4);
  --clock-hour-length: calc(var(--clock-width) * 0.2);
  --clock-thickness: 0.2rem;
  z-index: 60000;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--clock-width);
  height: var(--clock-width);
  border: 3px solid var(--clock-color);
  border-radius: 50%;

  &::before,
  &::after {
    position: absolute;
    content: "";
    top: calc(var(--clock-radius) * 0.25);
    width: var(--clock-thickness);
    background: var(--clock-color);
    border-radius: 10px;
    transform-origin: center calc(100% - calc(var(--clock-thickness) / 2));
    animation: spin infinite linear;
  }

  &::before {
    height: var(--clock-minute-length);
    animation-duration: 2s;
  }

  &::after {
    top: calc(var(--clock-radius) * 0.25 + var(--clock-hour-length));
    height: var(--clock-hour-length);
    animation-duration: 15s;
  }
}
.admin-page-container {
  padding-inline: 2rem !important;

  h5 {
    text-align: center;
  }
  form {
    width: 60%;
    margin: auto;

    & > * {
      margin: auto;
    }
  }
}

.edit-product-container {
  text-align: center;
}

@keyframes spin {
  to {
    transform: rotate(1turn);
  }
}

.loader--hide {
  display: none;
}

@keyframes changeOpacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes moveInLeft {
  from {
    opacity: 0;
    transform: translateX(-100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
