.profile-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  position: relative;

  .wrap-profile {
    width: 100%;

    .col-lg-12 {
      h3 {
        margin-block: 2rem;
      }
    }

    .profile-col {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }

    // .profile-orders {
    //   display: flex;
    //   flex-direction: column;
    //   align-items: flex-start;
    //   justify-content: center;
    //   width: 100%;

    //   table {
    //     width: 100%;
    //   }
    // }
  }
}
.box-profile {
  display: flex;

  h5 {
    margin-inline-end: 3rem;
    width: 200px;
    text-align: start;
  }
}

.box-profile-order {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  p {
    margin-bottom: 0 !important;
    min-width: max-content;
  }
  h6 {
    margin-bottom: 0 !important;
    min-width: max-content;
  }
}

.my-orders {
  background-color: #f3f3f3;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 5px 7px #999;
  margin-bottom: 1rem;

  p {
    margin-bottom: 0 !important;
  }

  h6 {
    margin-inline-end: 3rem;
    width: 200px;
    text-align: start;
  }

  h5 {
    text-align: start;
    margin-bottom: 1rem;
  }

  hr {
    color: #111 !important;
  }
}

.edit-user-profile {
  form {
    width: 50%;
    margin: auto;

    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 776px) {
  .profile-container {
    padding-inline: 1rem !important;
  }
  .order-container {
    padding-inline: 1rem !important;
  }
}

@media (max-width: 465px) {
  .box-profile {
    h5 {
      margin-right: 1rem !important;
    }
  }
  .box-profile-order {
    h6 {
      margin-right: 1rem !important;
    }
  }
}
