.cart-modal {
  background-color: #fff;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  padding: 2rem !important;

  .col-md-6 {
    img {
      width: 60%;
    }
  }

  .cart-modal-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: start;
  }

  .form-select {
    width: 120px;
    margin-top: 1rem;
  }

  .quantity-flex {
    display: flex;
    margin-top: 1rem;

    .shop-btn {
      height: 50px;
      line-height: 100%;
      padding-inline: 15px;
      border: 1px solid #111;
    }
  }

  .quantity-section {
    button {
      height: 50px;

      padding-inline: 10px;
      border: 1px solid #111;
      background-color: transparent;
    }
    span {
      font-size: 1.3rem;
    }
  }
}

@media (max-width: 768px) {
  .cart-modal {
    padding-inline: 3px !important;
    .col-md-6 {
      img {
        height: 150px;
        object-fit: contain;
      }
    }

    .cart-modal-content {
      h2 {
        font-size: 1.3rem;
      }
      h4 {
        font-size: 1.25rem;
      }
      p,
      a {
        font-size: 0.9rem;
      }
      .form-select {
        font-size: 0.9rem;
      }

      span {
        font-size: 1rem;
      }

      button,
      .shop-btn {
        padding-block: 5px !important;
        font-size: 0.9rem;
      }
    }
  }
}

.cart-added-message {
  z-index: 3000;
  opacity: 0;
  position: fixed;
  background-color: #b0eb81;
  padding: 1rem;
  bottom: 1rem;
  right: 1rem;
  transition: all ease 0.5s;
  pointer-events: none;
  font-size: 1.8rem;
  border-radius: 10px;
}

.cart-added-quantity {
  width: 80%;
  z-index: 3000;
  opacity: 0;
  position: fixed;
  background-color: #b0eb81;
  padding: 1rem;
  bottom: 1rem;
  left: 10%;
  transition: all ease 0.5s;
  pointer-events: none;
  font-size: 1.5rem;
  border-radius: 10px;
}

.show-message {
  opacity: 0.9;
}

@media (max-height: 700px) {
  .cart-modal {
    overflow-y: auto !important;
  }
}
