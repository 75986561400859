.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5000 !important;
  background-color: rgba($color: #000000, $alpha: 0.75);
}

.modal {
  position: fixed;
  padding: 10px;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 80%;
  max-width: 1050px;
  z-index: 10000 !important;
  overflow: hidden;

  margin: auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > button {
    background-color: transparent;
    border: 0;
    padding: 1em;
    height: 50px;
    width: 50px;
    line-height: 100%;
    border-radius: 100vw;
    transition: transform 500ms ease;
    margin-left: auto !important;

    svg {
      fill: #fff;
    }
    &:hover {
      transform: scale(1.3);
    }
  }

  input {
    border: 0;
    border-bottom: 1px solid #fff;
    background-color: transparent;
    padding: 0.5em 0.75em;
    font-size: 2rem;
    color: #fff;

    &:focus {
      outline: none;
      border-bottom-color: red;
    }
  }
}

.invisible-button {
  opacity: 0;
  pointer-events: none;
  cursor: default;
}

@media (max-height: 700px) {
  .modal {
    height: 100vh;
    overflow: auto;
  }
}

@media (max-width: 570px) {
  .modal {
    width: 100%;
  }
}
