.contact-form-left-col {
  text-align: start;
  padding-block: 3rem;

  h1 {
    margin-block-start: 3rem;
    margin-bottom: 2rem;
    font-weight: 700;
  }

  h4 {
    margin-block-start: 3rem;
  }

  p {
    margin: 0;
    padding: 0;
  }
}

.contact-form-right-col {
  display: flex;
  align-items: center;
  justify-content: space-around;
  form {
    text-align: start;
    section {
      display: flex;
      justify-content: space-between;

      input {
        width: 40%;
        padding: 0.5em 0.75em;
        border: 1px solid grey;

        &:focus {
          outline: none;
        }
      }
    }

    textarea {
      display: block;
      width: 100%;
      margin-top: 2rem;
      border: 1px solid grey;
      resize: none;
      height: 10rem;
      padding: 0.5em;

      &:focus {
        outline: none;
      }
    }

    .shop-btn {
      margin-block: 2rem;
    }
  }
}

@media (max-width: 500px) {
  .contact-form-container {
    padding-inline: 2rem !important;
  }
}
