.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-block: 2rem;
}

.header-logo {
  img {
    height: 100%;
  }
}

.mobile-nav-toggle {
  display: none;
}

.mobile-nav {
  display: none;
}

.header-nav {
  ul {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;

    li {
      padding-inline: 1.5rem;
      a {
        text-decoration: none;
        color: rgb(97, 96, 96);
        font-size: 1.1rem;
      }

      .nav-link {
        padding: 0 !important;
      }

      select {
        border: 0;
        font-size: 1.1rem;
        cursor: pointer;
        background-color: transparent;
      }
    }

    .dropdown-item {
      a {
        display: block;
        min-width: 100% !important;
      }
    }
  }
}

.header-icons {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    background-color: transparent;
    border: 0;
    text-align: center;
    line-height: 100%;
    padding-inline-end: 5px;

    svg {
      pointer-events: none;
    }
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.2rem;
    font-weight: 500;
  }
}

.search-bar {
  border: 1px solid #f3f2f2;
  position: relative;
  margin-right: 5px;
  input {
    width: 200px;
    border-radius: 0;
    border: 0;
    padding-inline-start: 13px;
    padding-block: 5px;
    &:focus {
      outline: none;
    }
  }

  button {
    svg {
      fill: #dfdcdc;
    }
  }

  .header-search-response {
    position: absolute;
    z-index: 30000;
    top: 0;
    left: 0;
    transform: translateY(36px);
    width: 100%;
    background-color: #fff;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.active-nav {
  font-weight: 600;
  color: #111 !important;
}

.search-suggestions {
  font-size: 0.9rem;
}

.search-bar-mobile {
  position: relative;
  border: 1px solid #f3f2f2;

  button {
    svg {
      fill: #dfdcdc !important;
    }
  }

  input {
    background-color: #fff;
    padding-block: 5px;
    opacity: 1;
    width: 150px;
    border: 0;
    border-radius: 10px;
    // position: absolute;
    // transform: translateX(45px);
    z-index: 2 !important;
    animation-name: drop;
    animation-duration: 300ms;
    padding-inline: 5px;

    &:focus {
      outline: none;
    }
  }
}

.header-search-response-mobile {
  height: max-content;
  width: 100%;
  position: absolute;
  left: 0;
  top: 30px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 1px 1px #f3f2f2;

  a:hover {
    background-color: #fff;
  }
}

@media (max-width: 1301px) {
  .search-bar {
    input {
      width: 150px;
    }
  }
  .header-nav {
    ul {
      li {
        padding-inline: 1.2rem;
      }
    }
  }
  .header-icons {
    button {
      padding-inline-end: 0.7rem;

      svg {
        height: 20px;
      }
    }
  }
  .nested-input-search {
    padding-inline: 5rem !important;
  }
}

@media (max-width: 1010px) {
  .header-container.container-padding {
    padding-inline: 1rem;
  }
  .nested-input-search {
    padding-inline: 1rem !important;
  }
}

@media (max-width: 880px) {
  .header-logo {
    img {
      width: 70%;
    }
  }
  .header-nav {
    ul {
      li {
        padding-inline: 0.6rem;
        a {
          font-size: 1rem;
        }
      }
    }
  }
  .header-icons {
    button {
      padding-inline-end: 0.5rem;

      svg {
        height: 18px;
      }
    }
  }
}

@media (max-width: 768px) {
  .mobile-nav-toggle {
    display: block;
    position: fixed;
    background: url("../../../assets/icon-hamburger.svg") no-repeat center
      center;
    width: 2rem;
    border: 0;
    aspect-ratio: 1;
    top: 2rem;
    right: 2rem;
    z-index: 9999;
  }

  .header-nav,
  .header-icons,
  .header-logo {
    display: none;
  }

  .header-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 7000;
    padding-inline: 0 !important;
  }

  .mobile-nav {
    position: fixed;
    inset: 0 0 0 30%;
    flex-direction: column;
    padding: 10rem 2rem;
    z-index: 1000;
    font-size: 1.1rem;
    background-color: #fff;
    overflow-y: auto;
    animation-name: fade;
    animation-duration: 500ms;

    .nav-link {
      padding: 0 !important;
    }

    img {
      width: 50%;
      margin-bottom: 2rem;
    }
  }

  .mobile-nav-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;

    a,
    button,
    select {
      background-color: transparent;
      text-decoration: none;
      color: #111;
      font-size: 1.2rem;
      border: none;
      cursor: pointer;
    }

    select {
      option {
        font-size: 0.8rem;
      }
    }
  }

  .mobile-nav-items {
    ul {
      list-style: none;
      display: flex;
      flex-direction: column;
      padding: 0;
      margin: 0;
      text-align: start;

      li {
        padding-inline: 1.5rem;
        padding-block: 1rem;
        a,
        select {
          text-decoration: none;
          color: #111;
          font-size: 1.2rem;
          border: none;
          cursor: pointer;
        }
        select {
          margin-left: -4px !important;
          option {
            font-size: 0.8rem;
          }
        }
      }
    }
  }

  .mobile-nav-icons {
    display: flex;
    justify-content: space-between;
    button {
      background-color: transparent;
      border: 0;
      text-align: center;
      line-height: 100%;
      padding-inline-end: 0.5rem;
    }
  }

  .mobile-nav-price {
    text-align: end;
    margin-right: 10px;
  }

  .mobile-nav-text {
    margin-top: 2rem;
  }
  .mobile-nav-toggle[aria-expanded="true"] {
    .mobile-nav {
      display: flex;
    }
    background: url("../../../assets/icon-close.svg") no-repeat center center;
  }
}

@media (max-width: 450px) {
  .mobile-nav-icons {
    flex-direction: column;
    align-items: flex-start;
  }

  .search-bar-mobile {
    margin-bottom: 10px;
  }

  .mobile-nav-price {
    text-align: start;
    margin-left: 1px;
  }
}

@media (max-width: 400px) {
  .mobile-nav {
    padding-inline: 1rem !important;
  }

  .search-bar-mobile {
    button {
      svg {
        height: 14px;
        width: 14px;
      }
    }
  }
}

@media (max-width: 350px) {
  .search-bar-mobile {
    width: 100%;
    input {
      width: 80% !important;
    }
    button {
      width: 20% !important;
    }
  }
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
