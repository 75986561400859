.shop-content-container {
  margin-top: 2rem;

  #loader {
    top: 0 !important;
  }
}

.shop-content-height-col {
  height: 100%;
}

.shop-content-search-input {
  border: 1px solid #f5f5f5;
  padding: 0.5em 0.75em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  input {
    border: 0;
    color: transparent;
    color: #111;
    width: 100%;
    &:focus {
      outline: none;
    }
  }

  button {
    border: 0;
    background-color: transparent;
    svg {
      fill: #111;
    }

    &:focus {
      outline-offset: 2px;
    }
  }
}

.shop-categories-ul {
  animation-name: changeOpacity;
  animation-duration: 0.5s;

  li {
    margin-bottom: 3px !important;
  }
}

.shop-content-filter-tags {
  margin-top: 1rem;

  display: flex;
  flex-wrap: wrap;
  section {
    width: max-content;
    border: 1px solid #f5f5f5;
    margin-right: 10px;
    margin-bottom: 5px;
    padding: 3px;
  }
  button {
    border: 0;
    background-color: transparent;
    margin-left: 5px;
    color: red;
  }
}

.shop-content-filtering {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      margin: 0;
      padding: 0;
      text-align: start;

      button {
        border: 0;
        background-color: transparent;
        color: grey;
        &:focus {
          outline-offset: 2px;
        }
      }
    }
  }
}

.shop-content-filtering-item {
  text-align: start;
}

.shop-content-filtering-item > button {
  border: 0;
  background-color: transparent;
  text-align: start;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 1.6rem;
  margin-block: 1rem;

  svg {
    pointer-events: none;
    margin-top: 3px;
  }
}

.shop-content-filtering .size {
  ul {
    display: flex !important;
    flex-wrap: wrap;
    li {
      button {
        border: 1px solid #f3f3f3;
        margin-inline-end: 1rem;
        padding: 0.5em;
        color: #333;
      }
    }
  }
}

.shop-content-filtering .colors {
  ul {
    display: flex !important;
    flex-wrap: wrap;
    li {
      button {
        $size: 20px;
        border: 1px solid #f3f3f3;
        height: $size;
        width: $size;
        border-radius: 500vw;
        margin-inline-end: 1rem;
      }
    }
  }
}

#red {
  background-color: red !important;
}
#black {
  background-color: black;
}
#yellow {
  background-color: yellow !important;
}
#blue {
  background-color: blue;
}

.shop-content-filtering .tags {
  ul {
    display: flex !important;
    flex-wrap: wrap;
    li {
      button {
        background-color: #f5f5f5;
        color: #111;
        margin-inline-end: 1rem;
        margin-block-end: 0.5rem;
        padding: 0.5em 1em;
      }
    }
  }
}

.shop-content-container .row .col-lg-4 {
  position: relative;
  padding: 5px;
  text-align: start;

  img {
    width: 100% !important;
    margin-bottom: 1rem;
  }

  .tag {
    position: absolute;
    top: 15px;
    left: 5px;
    background-color: #fff;
    width: 30%;
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
  }

  h5 {
    font-size: 1rem;
    font-weight: 400;
  }

  h4 {
    font-size: 1.2rem;
  }
}

.shop-content-top-left {
  text-align: start;
}

.shop-content-top-right {
  text-align: end;
  display: flex;
  align-items: center !important;
  justify-content: flex-end;
  select {
    margin-inline-start: 1rem;
    border: 0;
    cursor: pointer;
    &:focus {
      outline-offset: 2px;
    }
  }
}

.shop-dropdown {
  display: flex !important;
  align-items: flex-end !important;
}

.shop-dropdown .dropdown-toggle.nav-link {
  width: max-content !important;
  color: #333 !important;
}

.dropdown-menu.show {
  text-align: right;
  border: 0 !important;
  // background-color: transparent;
  // max-width: max-content;
  transform: translate(0px, 30px) !important;
}

.dropdown-item {
  &:hover {
    background-color: transparent !important;
  }
  cursor: default;
  button {
    width: 100%;
    background-color: #fff;
    padding: 0.25em 0.5em;
    border: 0;
    border-top: 1px solid rgba(197, 195, 195, 0.5);
  }
}

.shop-content-search-input {
  margin-bottom: 0.3rem !important;
}

.search-suggestions-wrap {
  margin: 0 !important;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  border-radius: 10px;
  padding-block: 0.5rem;
}

.search-suggestions {
  color: #111;
  display: block;
  text-align: start;
  text-decoration: none;
  padding-inline-start: 0.5rem;
  margin-block: 5px;

  &:hover {
    background-color: #f5f5f5;
    color: inherit;
  }
}

@media (max-width: 500px) {
  .shop-details-text {
    margin-block: 2rem !important;
  }

  .shop-content-top-left,
  .shop-content-top-right {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .shop-content-filtering-item {
    button {
      font-size: 1.3rem;
    }
  }
}
