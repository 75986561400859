.confirm-delete-container {
  position: absolute;
  inset: 0;
  top: -2px;
  background-color: #ffffffe7;
  z-index: 10;

  button {
    margin-inline-end: 1rem;
  }
}
