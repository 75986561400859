.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-block: 3rem;

  input {
    width: 60%;
    margin-block: 1rem;
    border: 1px solid #f5f5f5;
    padding: 0.5rem;
  }
}

@media (max-width: 776px) {
  .login-form-container {
    padding-inline: 1rem !important;
  }
  .login-form {
    input {
      width: 80%;
    }
  }
}

@media (max-width: 500px) {
  .login-form {
    input {
      width: 100%;
    }
  }
}
