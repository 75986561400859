.react-paginate {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-block: 2rem;

  a {
    text-decoration: none;
    color: #333;
    padding: 0.25em 0.5em;
    margin-inline: 0.5rem;
  }

  a,
  li {
    color: rgb(97, 97, 97);
  }
}

.active-page {
  color: #fff !important;
  background-color: #698afe;
  border-radius: 5px;
}

.pages-animate {
  animation-name: changeOpacity;
  animation-duration: 0.8s;
}

.shop-content-card {
  height: 300px;
  margin-bottom: 3rem;
  position: relative;
  img {
    height: 80%;
    object-fit: contain;
  }

  .add-to-cart-hover {
    border: 0;
    background-color: transparent;
    color: red;
    font-size: 1.3rem;
    background-color: #fff;
  }

  @media (hover) {
    .add-to-cart-hover {
      position: absolute;
      bottom: 50px;
      right: 0;
      left: 0;
      opacity: 0;
      transition: all 500ms ease;
      text-align: start;
    }
  }

  &:hover {
    .add-to-cart-hover {
      bottom: 20px;
      opacity: 1;
    }
  }
}
