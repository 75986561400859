.shopping-cart-container {
  margin-block: 2rem;
  min-height: 20vh;
}

.shopping-cart-titles {
  display: flex;
  justify-content: center;
  align-items: center;

  h4 {
    margin-inline-start: 8rem;
  }
}
.shopping-cart-single-product-mobile {
  display: none;
}

.shopping-cart-single-product {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block-end: 1rem;
  height: 100px;

  img {
    height: 100%;
  }

  .name {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .quantity {
    display: flex;
    align-items: center;
    justify-content: center;
    // font-size: 1.2rem;
    vertical-align: baseline;
    margin-inline: 1.6rem;
    // border: 1px solid red;

    p {
      padding-inline: 0.5rem;
    }

    button {
      height: max-content;
      font-size: 1rem;
      margin-inline: 0.5rem;
      border: 0;
      background-color: transparent;
      margin-top: -1rem;
    }
  }

  .total {
    font-size: 1.3rem;

    width: 60px;
  }

  button {
    border: 0;
    height: 50px;
    width: 50px;
    border-radius: 500vw;
    margin-top: -1rem;
  }
}

.checkout {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: start;
  padding-left: 3rem;

  h4 {
    margin-bottom: 2rem;
  }

  .shopping-cart-input {
    border: 1px solid #cfcece;

    display: flex;
    justify-content: space-between;
    margin-bottom: 4rem;

    input {
      border: 0;
      width: 100%;
      padding-inline-start: 0.3rem;
    }

    button {
      padding: 0.3em 1em;
      height: 100% !important;
      outline: 1px solid #111;
    }
  }

  .shopping-cart-checkout {
    background-color: #f3f2ee;
    padding: 2rem;
    font-size: 1.2rem;
    span {
      color: red;
    }
  }
}

@media (max-width: 1250px) {
  .shopping-cart-checkout .shop-btn {
    padding: 0.5em 0.75em;
  }

  .quantity {
    margin-inline: 5px !important;
  }

  .total {
    font-size: 1rem !important;
  }

  .shopping-cart-single-product {
    button {
      height: 30px;
      width: 30px;
    }
  }

  .shopping-cart-titles {
    h4 {
      margin-inline-start: 6rem;
    }
  }
}

@media (max-width: 992px) {
  .checkout {
    padding: 0;
    h4 {
      margin-top: 2rem;
      text-align: center !important;
      margin: auto;
      margin-block: 2rem;
    }
  }

  .shopping-cart-checkout {
    width: 60%;
    margin: auto;
  }

  .shopping-cart-input {
    width: 60%;
    margin: auto;
  }
}

@media (max-width: 768px) {
  .shopping-cart-single-product {
    display: none;
  }

  .shopping-cart-container {
    padding-inline: 1rem !important;
  }

  .shopping-cart-single-product-mobile {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: auto;
    margin-block: 0.5rem;
  }
  img {
    width: 40%;
  }

  .shopping-mobile {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    justify-content: center;

    p {
      padding: 0 !important;
      margin: 0 !important;
    }
    & > * {
      width: 25%;
    }

    button {
      border: 0;
      background-color: transparent;
    }
  }

  .name {
    p {
      margin-top: 1rem !important;
      margin-bottom: 0 !important;
    }
  }

  .shopping-cart-titles {
    display: none;
  }

  .checkout {
    width: 80%;
    margin: auto;
    padding: 0;

    .shopping-cart-input {
      width: 100%;
    }

    .shopping-cart-checkout {
      width: 100%;
      text-align: center;
    }
  }
}

@media (max-width: 475px) {
  .checkout {
    width: 100%;
    padding: 0;
  }
}
