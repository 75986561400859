.upper-header-container {
  background-color: #f5f5f5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100vw;
  padding-block: 1rem;

  p {
    margin-top: 0;
    margin-bottom: 0 !important;
    color: #333;
  }
}

.upper-header-button-section {
  display: flex;
  width: 30%;
  justify-content: space-evenly;
  align-items: center;

  a,
  button {
    border: 0;
    cursor: pointer;
    background-color: transparent;
    text-decoration: none;
    color: #333;
    padding: 0.125em 1.5em;
    text-transform: uppercase;

    &:focus {
      outline-color: rgba(51, 51, 51, 0.479) !important;
    }
  }

  select {
    border: 0;
    font-size: 1.1rem;
    cursor: pointer;
    background-color: transparent;
  }

  p {
    padding: 0.125em 1.5em;
  }
}

@media (max-width: 1301px) {
  .upper-header-button-section {
    width: 40%;
  }
}

@media (max-width: 900px) {
  .upper-header-button-section {
    width: 50%;
  }
}

@media (max-width: 880px) {
  .upper-header-container.container-padding {
    padding-inline: 1rem !important;
  }
}

@media (max-width: 768px) {
  .upper-header-container {
    display: none;
  }
}
