.home-cards-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
  margin-bottom: 7rem;
}

.card-inner-container {
  position: relative;
  margin-bottom: 1rem;

  img {
    width: 100%;
  }
  section {
    position: absolute;
    bottom: -40px;
    width: 80%;
    left: 10%;
    background-color: #fff;
    text-align: start;
    padding: 1rem;

    h4 {
      font-weight: 400;
    }

    a {
      text-decoration: none;
      color: #111;
      padding: 0.3em;
      border-bottom: #111 solid 2px;
      font-weight: 600;
    }
  }
}

@media (max-width: 992px) {
  .card-inner-container {
    margin: auto;
    margin-bottom: 4rem;
  }
}

@media (max-width: 435px) {
  .card-inner-container.home-banner.col-lg-4 {
    min-width: 100vw !important;
    margin-bottom: 5rem;
    img {
      width: 80%;
    }
    section {
      width: 60%;
      left: 20%;
      bottom: -50px;
      h4 {
        font-size: 1.2rem;
      }
      a {
        font-size: 1rem;
      }
    }
  }
}
