.product-month-container {
  background-color: #f3f2ee;
  padding-block: 7rem;
}

.product-month-first-col {
  h1 {
    text-align: start;
  }
}

.product-month-second-col {
  position: relative;
  img {
    display: inline-block;
    width: 90%;
    margin-right: 6rem;
  }

  p {
    margin: 0;
    $size: 100px;
    background-color: #111;
    color: #fff;
    width: $size;
    height: $size;
    padding: 1em;
    font-size: 1.1rem;
    border-radius: 100vw;
    text-align: center;
    position: absolute;
    top: 0;
    right: 4rem;
  }
}

.product-month-third-col {
  text-align: start;

  h1 {
    margin-top: 1rem;
  }

  p.red-text {
    text-transform: uppercase;
  }

  a {
    margin-top: 1rem;
  }
}

.product-month-third-col,
.product-month-second-col,
.product-month-first-col {
  padding: 1rem;
}

@media (max-width: 1301px) {
  .product-month-second-col {
    p {
      top: -3rem;
      right: 2rem;
    }
  }
}

@media (max-width: 992px) {
  .product-month-container .row .col-lg-4 {
    margin: auto;
    margin-block-end: 3rem;
  }
}
