#loader {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 50000;

  width: 100%;
  height: 100%;
  min-height: 200px;

  svg {
    position: absolute;
    top: 0;
    left: calc(50% - 4em);
  }
}
