.home-changing-divs {
  margin-bottom: 3rem;
}

.home-changing-divs-btns {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-block: 1rem;

  button {
    background-color: transparent;
    border: 0;
    font-size: 1.4rem;
    padding-inline: 1em;
    padding-block: 0.5em;
  }
}

.best-sellers,
.hot-sales,
.new-arrivals {
  animation-name: changeOpacity;
  animation-duration: 1s;
}

.home-changing-divs-cards .row .col-lg-3 {
  position: relative;
  padding: 5px;
  text-align: start;

  img {
    width: 100% !important;
    margin-bottom: 1rem;
    cursor: pointer;
  }

  .tag {
    position: absolute;
    top: 15px;
    left: 5px;
    background-color: #fff;
    width: 30%;
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
  }

  h5 {
    font-size: 1rem;
    font-weight: 400;
  }

  h4 {
    font-size: 1.2rem;
  }
}

.active-btn {
  font-weight: 600;
  box-shadow: 0 4px 4px -4px red;
  animation-name: moveInLeft;
  animation-duration: 1s;
}

@media (max-width: 435px) {
  .home-changing-divs-btns {
    display: flex;
    flex-direction: column;
  }
}
