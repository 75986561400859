.checkout-component-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: start;
  .row {
    width: 100%;
  }

  input[type="checkbox"] {
    cursor: pointer;
  }
}

.checkout-coupon {
  margin-block: 2rem;
  background-color: #f5f5f5;
  padding-inline: 1.25rem;
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  border-top: 2px solid greenyellow;

  button {
    border: 0;
    background-color: transparent;
    font-weight: 600;
  }

  svg {
    margin-top: -3px;
  }
}

.checkout-form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.checkout-form-double {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .checkout-form-item {
    width: 40%;
    // border: 1px solid red;
  }
}

.checkout-form-item {
  display: flex;
  flex-direction: column;
  margin-block-end: 1.2rem;

  label {
    font-size: 1.1rem;
    margin-block-end: 7px;
    span {
      color: red;
    }
  }

  input {
    border: 1px solid #f5f5f5;
    padding: 0.75rem 1rem;

    &:focus {
      outline-color: greenyellow;
    }
  }
}

#address {
  margin-bottom: 5px;
}

.checkout-form-checkbox {
  margin-block: 1rem;
  label {
    margin-inline-start: 0.5rem;
  }
}

.checkout-form-order-segment {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  background-color: #f5f5f5;
  padding-top: 1rem;
  padding-inline: 1.5rem;
  height: max-content;
  ul {
    list-style: decimal !important;
    padding-inline-start: 1.3rem;
    li {
      padding: 0;
    }
  }
}

.checkout-order-item {
  display: flex;
  justify-content: space-between;
}

.checkout-form-btn {
  margin-block-end: 1rem;
}

.checkout-form-input-coupon {
  input {
    border: 0;
    padding: 0.5rem;
    &:focus {
      outline-color: greenyellow;
    }
  }
}

.error-txt {
  color: red;
  text-align: center;
  padding-bottom: 0.5rem;
}

.empty-cart {
  text-align: center;
  width: 100%;
  padding-block: 3rem;
  p {
    font-size: 2rem;
  }
}

@media (max-width: 991px) {
  .checkout-form-order-segment {
    margin-bottom: 1rem;
  }
}
@media (max-width: 670px) {
  .checkout-form-double {
    flex-direction: column;

    .checkout-form-item {
      width: 100%;
    }
  }
}

@media (max-width: 500px) {
  .checkout-component-container {
    padding-inline: 2rem !important;
  }
}
