.footer-container {
  padding-block-start: 4rem;
  background-color: #111;
  color: #fff;
}

.footer-container .row .col-lg-3 {
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.footer-title {
  text-transform: uppercase;
  font-size: 1.3rem;
}

.footer-first-col {
  p {
    text-align: start;
    margin-top: 1.5rem;
  }
}

.footer-credit-cards {
  a {
    padding-inline-end: 0.5em;
  }
}

.footer-list {
  list-style: none;
  padding: 0 !important;
  margin: 0;
  text-align: start;
  margin-bottom: 14px !important;
}

.footer-list-item {
  margin-block-start: 0.5em;
  a {
    text-decoration: none;
    color: #fff;
  }
}

.footer-newsletter {
  p {
    text-align: start;
  }
  button {
    border: 0;
  }
}

.footer-input-section {
  background-color: transparent !important;
  border: 0;
  border-bottom: 1px solid #fff;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-block-end: 4px;

  input {
    background-color: transparent;
    border: 0;
    color: #fff;
    width: 100%;

    &:focus {
      outline: none;
    }
  }

  button {
    background-color: transparent;
  }
}

.footer-copyright {
  margin-top: 5rem;
  padding-bottom: 2rem;
  margin-bottom: 0;
}

@media (min-width: 992px) and (max-width: 1099px) {
  .footer-first-col {
    img {
      width: 80%;
    }
  }
}

@media (max-width: 768px) {
  .footer-first-col {
    img {
      width: 50%;
    }
  }
}

@media (max-width: 600px) {
  .footer-first-col {
    img {
      width: 60%;
    }
  }
}

@media (max-width: 500px) {
  .footer-container {
    padding-inline: 2rem !important;
  }
}

@media (max-width: 400px) {
  .footer-first-col {
    img {
      width: 70%;
    }
  }
}
