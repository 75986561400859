.add-to-cart-btn {
  border: 0;
  background-color: transparent;

  &:focus {
    svg {
      fill: greenyellow;
    }
  }
}
