.pages-navlist-container {
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-block: 2rem;

  section {
    margin-top: 1rem;
    a {
      text-decoration: none;
      color: #111;
      text-transform: capitalize;
    }
    p {
      display: inline;
      color: grey;
      text-transform: capitalize;
    }
  }
}
