.home-carousel {
  width: 100vw;

  //ovaj min height treba da bude u stvari definisana visina slike
  height: 600px;
  margin: 0 !important;
  padding: 0 !important;

  &-item {
    //ovaj height se poklapa sa onim gore
    height: 600px;
    max-width: 100vw;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.carousel-caption {
  color: #fff;
  height: 100%;
  display: flex;
  max-width: max(500px, 35vw);
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-inline: 2rem;
  bottom: 0 !important;
}

.home-carousel-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding-block-start: 50px;

  p {
    font-size: 1.2rem;
  }

  section {
    text-align: start;
    p {
      width: 80%;
      font-size: 1.3rem;
    }
  }
}

.home-carosuel-socials {
  width: 200px;
  display: flex;
  justify-content: space-between;
  svg {
    fill: #fff;
  }
}

.carousel-indicators {
  margin-bottom: 0 !important;
}

@supports (backdrop-filter: blur(0.75em)) {
  .carousel-caption {
    background-color: hsla(0, 0%, 100%, 0.5);
    backdrop-filter: blur(0.1rem);
    color: #111;
  }
  .home-carosuel-socials {
    svg {
      fill: #111;
    }
  }
}

@media (max-width: 830px) {
  .home-carousel-content {
    padding-bottom: 3rem;
    section {
      p {
        width: 80%;
        font-size: 1rem;
      }
    }
  }
}

@media (max-width: 580px) {
  .home-carousel-content {
    padding-block: 10px;
    padding-bottom: 3rem;

    .shop-btn {
      font-size: 0.8rem !important;
    }

    section {
      p {
        width: 100%;
      }
    }
  }
}
