.unauthorized-container {
  position: absolute;
  inset: 0;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //   z-index: 30000;

  h1 {
    font-size: 6rem;
    font-weight: 800;
  }

  h3 {
    font-weight: 600;
    margin-bottom: 2rem;
  }

  p {
    margin-bottom: 2rem;
  }
}
