.shop-details-preview-container {
  margin-block: 2rem !important;
  min-height: 200px;

  .form-select {
    width: 100px;
  }

  .quantity-section {
    button {
      padding: 0.5rem;
      background-color: transparent;
      border: 1px solid #111;
    }
  }
}

.shop-details-preview-display-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: max-content;
}

.shop-detail-preview-thumbnails {
  height: 500px;
  width: max-content;
  overflow-y: auto;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.shop-detail-preview-big-image {
  height: 500px;
  width: 400px;
  margin: auto;
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}

@media (max-width: 1200px) {
  .shop-detail-preview-thumbnails {
  }
}

@media (max-width: 992px) {
  .shop-detail-preview-thumbnails {
    flex-direction: row;
    height: max-content;
    width: 600px;
    margin: auto;
    margin-top: 0.5rem;
  }
}

@media (max-width: 768px) {
  .shop-detail-preview-thumbnails {
    width: 400px;
  }
}

@media (max-width: 576px) {
  .shop-detail-preview-big-image {
    height: 300px;
    img {
    }
  }
  .shop-details-preview-display-flex {
    min-width: 100%;
  }
}

.shop-details-preview-text-details {
  text-align: start;
  h3 {
    margin-block: 1rem;
  }

  p {
    text-align: start;
  }

  label {
    text-transform: uppercase;
  }
}

.shop-detail-color {
  line-height: 100%;
  margin-block: 1rem;
}

.shop-detail-color-label {
  color: transparent;
  overflow: hidden;
  height: 20px;
  width: 20px;
  border-radius: 500vw;
}

.shop-details-quantity-add-cart {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin-bottom: 1rem;

  input {
    width: 60px;
    text-align: end;
    font-size: 1.2rem;
    padding: 0.125em 0.2em;
    border: 1px solid rgb(190, 187, 187);
  }
}

.shop-details-add-wishlist {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  button {
    border: 0;
    background-color: transparent;

    svg {
      pointer-events: none;
    }
  }
}

.shop-details-p {
  margin-top: 1rem;
  color: rgb(136, 134, 134);
  span {
    text-transform: capitalize;
  }
}

.darker {
  color: #111;
}

.materials,
.care,
.origin {
  animation-name: changeOpacity;
  animation-duration: 1s;
}

.shop-details-changing-divs {
  margin-block: 3rem !important;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-bottom: 1px solid lightgrey;

  button {
    border: 0;
    background-color: transparent;
    font-size: 1.6rem;
    // border-bottom: 2px solid grey;
    padding-bottom: 0px;
    padding-inline: 5rem;
    z-index: 100;
  }
}

.shop-details-change-content {
  min-height: 40vh;
  text-align: start;
}

.shop-detail-related-products .row .col-lg-3 {
  position: relative;
  padding: 5px;
  text-align: start;

  img {
    width: 100% !important;
    margin-bottom: 1rem;
  }

  .tag {
    position: absolute;
    top: 15px;
    left: 5px;
    background-color: #fff;
    width: 30%;
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
  }

  h5 {
    font-size: 1rem;
    font-weight: 400;
  }

  h4 {
    font-size: 1.2rem;
  }
}

.active-btn-shop {
  font-weight: 600;
  box-shadow: 0 4px 4px -4px red;
}

.sale {
  text-decoration: line-through;
  color: lightgrey;
  font-size: 1.3rem;
}

.input-hidden {
  position: absolute;
  left: -9999px;
}

input[type="radio"] + label {
  border: 2px solid transparent;
  cursor: pointer;
}
input[type="radio"]:checked + label {
  border: 1px solid black;
  //   box-shadow: 0 0 1px 1px rgb(17, 32, 17);
}

.shop-detail-size-label {
  border-radius: 100vw;
  padding: 2px;
}

input[type="number"]::-webkit-inner-spin-button {
  margin-left: 5px;
  opacity: 1;
}

@media (max-width: 1200px) {
  .shop-details-preview-text-details {
    margin-top: 2rem;
  }
  .shop-details-quantity-add-cart {
    flex-direction: column;
    align-items: flex-start;

    button {
      margin-top: 1rem;
    }
  }
  .shop-details-add-wishlist {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (max-width: 992px) {
  // .shop-detail-preview-thumbnails {
  //   margin: auto;
  //   overflow-x: auto;
  //   height: max-content;
  //   margin-block: 0.5rem;
  //   flex-direction: row;
  //   align-items: center;
  //   justify-content: center;
  // }

  .shop-details-preview-display-flex {
    display: flex;
    flex-flow: column-reverse;
  }

  .shop-details-changing-divs {
    button {
      margin-block: 1rem;
    }
  }
}

@media (max-width: 768px) {
  // .shop-detail-preview-big-image {
  //   img {
  //     height: 300px;
  //     width: 100%;
  //     // object-fit: contain;
  //   }
  // }

  // .shop-detail-preview-thumbnails {
  //   overflow-y: auto;
  //   height: 110px;

  //   img {
  //     width: 110px;
  //     height: 100%;
  //   }
  // }

  .shop-details-preview-container {
    padding-inline: 2rem !important;
  }

  .shop-details-add-wishlist {
    flex-direction: column;
    align-items: flex-start;
    button {
      margin-block: 0.5rem;
    }
  }

  .shop-detail-related-products {
    margin-top: 2rem;
    .row {
      .col-lg-3 {
        width: 60%;
        margin: auto;
      }
    }
  }
}
